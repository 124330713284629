import { datadogRum } from '@datadog/browser-rum';

let register = (/* branding, eid */) => {};

// do not use destructuring here, envify would not work
const datadogAppId = "f8c3d163-f1f3-4427-bbb7-49b15bcf195f";
const datadogToken = "pub023818b83d17673eb630bcbb2c61c16a";
const env = "aws-dev";
const version = "1.150.0";

if (datadogAppId) {
    register = (branding, eid) => {
        datadogRum.init({
            applicationId: datadogAppId,
            clientToken: datadogToken,
            site: 'datadoghq.com',
            service: 'webapp',
            env,
            version,
            sampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: 'allow'
        });

        datadogRum.addRumGlobalContext('eid', eid);
        datadogRum.addRumGlobalContext('branding', branding);

        datadogRum.startSessionReplayRecording();
    };
}

module.exports = {
    register
};
