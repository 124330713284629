import {
    last,
    isNumber,
    isString,
    isArray,
    sortBy,
    extend,
} from 'lodash';
import moment from 'moment-timezone';
import { stripHTML } from '../utils/string';
import { fromQueryString } from '../utils/url';
import i18nWithFallback from '../utils/i18n-with-fallback';

/**
 * For some reason angular digests the body of the post multiple times
 * (probably for rendering reasons), unfortunately, this is not avoidable
 * even with the `::` in the view. That's why, since we don't have any changes
 * on the content, we setup a shallow cache for every post's content so that we
 * can spare the client further calculations on links and hashtags.
 */
const POSTS_CACHE = {};
const QUESTIONS_CACHE = {};

/** @const ICON_ENDPOINT the endpoint to get icons */
const ICON_ENDPOINT = 'webapp/icon';
const SIGNED_ASSET_ENDPOINT = 'webapp/signed-asset';

export function takeInitials(str) {
    // We remove "[" and "]" characters as they are used in the draft prospect representation
    const names = (str || '').trim().replace(/[[\]]+/g, '').split(' ');
    if (names.length === 0) return '';
    if (names.length === 1) return names[0][0];

    return names[0][0] + last(names)[0];
}

/* @ngInject */
function assetUrlFilter(handlerService) {
    return src => {
        if (!src || !src.length) {
            return src;
        }

        if (src[0] === '/') {
            return src;
        }

        try {
            new URL(src);
            return src;
        } catch (err) {
            // this is just not an URL, we move along.
        }

        src = src.replace('{{couchbase_url}}/{{default_db}}/', '');
        // extract query params, most of the time used to avoid the cache
        const [ file, extraQueryParams ] = src.split('?');
        let [ id, ...attachment ] = file.split('/');
        attachment = attachment.join('/');

        if (attachment && attachment.endsWith('.m3u8')) {
            return handlerService.generateBackendUrl(
                `${SIGNED_ASSET_ENDPOINT}/${id}/${attachment}`
            );
        }

        const params = extend(
            {
                id,
                attachment
            },
            fromQueryString(extraQueryParams)
        );

        return handlerService.generateBackendUrl('webapp/doc', params);
    };
}

/* @ngInject */
function iconUrlFilter(handlerService) {
    return src => {
        if (!src || !src.length) {
            return src;
        }
        return handlerService.generateBackendUrl(`${ICON_ENDPOINT}/${src.split('/')[0]}`);
    };
}

export function time(ts, format) {
    if (!isNumber(ts)) return 'N/A';
    return moment.unix(ts).format(format);
}

export function shortDate(ts) {
    if (!isNumber(ts)) return 'N/A';
    return moment.unix(ts).format('ddd, MMM D');
}

export function shortTime(ts) {
    if (!isNumber(ts)) return 'N/A';
    return moment.unix(ts).format('LT');
}

export function timeDisplay(ts) {
    ts = Number.parseInt(ts);
    if (isNaN(ts)) return '-';

    const format = ts < 3600 ? 'mm:ss' : 'HH:mm:ss';
    return moment.utc(ts * 1000).format(format);
}

export function humanizeDuration(ts, unit = 'seconds') {
    if (!isNumber(ts)) return 'N/A';
    return moment.duration(-ts, unit).humanize(true);
}

export function unescapeAmps(text) {
    return (text || '')
        .replace(new RegExp(/&amp;/, 'g'), '&')
        // This replacement is needed because we may have
        // other `&#10;` already
        .replace(new RegExp(/\n/, 'g'), '&#10;')
        .replace(new RegExp(/&#10;/, 'g'), '<br/>');
}

export function unixFromNow(ts) {
    return moment.unix(ts).fromNow();
}

unixFromNow.$stateful = true;

// enables @mentions and #hashtags in feed posts
export function feedLinky(content, links, action) {
    if (!content || !isString(content) || (!content.includes('@') && !content.includes('#')) || !action || !action.path) {
        return content;
    }

    console.groupCollapsed('[feedLinky] linking');
    // `feedLinky` is usually executed after `linky`.
    // `linky` converts special čhàrš to HTML entities (&#269;h&#224;r&#353;) which means
    // indexes that links have won't be correct anymore.
    // this converts HTML entities to chars
    // (scripts and unsafe html attributes will be stripped by linky)
    let rawContent = $('<div>').html(content).html();
    console.log('content', rawContent);

    // make sure all links use the redirection proxy
    const urlRegex = /<a\s(?:.(?!=href))*?href="(?:[^"]*)"[^>]*?>(?:.*?)<\/a>/g;
    const urlLinks = rawContent.match(urlRegex);
    (urlLinks || []).forEach(link => {
        const el = $(link);
        const url = el.attr('href');
        rawContent = rawContent.replace(url, `/api/v1/open-proxy?url=${url}`);
    });

    links = isArray(links) ? links : [];

    const hashtags = (rawContent.match(/\B(\#[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF]+\b)(?!;)|(\#[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF]+)/g) || []).filter(h => !links.map(l => l.text).includes(h));

    for (const hashtag of hashtags) {
        if (!links.find(l => l.text === hashtag)) {
            links.push({
                index: content.indexOf(hashtag),
                text: hashtag
            });
        }
    }

    links = sortBy(links, 'index');

    console.log('injecting links', links);

    const parsed = [];
    for (const link of links) {
        if (parsed.includes(link.text)) {
            continue;
        }

        const params = encodeURIComponent(
            JSON.stringify(extend({}, action.params || {}, {
                text: link.text,
                metadata: link.metadata
            }))
        );

        const linkText = `<a href="appscript://${action.path}?${params}" class="appscript-link">${link.text}</a>`;

        rawContent = rawContent.replace(new RegExp(`${link.text}`, 'g'), linkText);
        parsed.push(link.text);
    }

    console.log('done', rawContent);
    console.groupEnd('[feedLinky] linking');

    return rawContent;
}

export function postLinks($filter, content, links, action) {
    if (!POSTS_CACHE[content]) {
        const linky = $filter('linky');
        let rawContent = linky(content, '_blank');
        rawContent = feedLinky(rawContent, links, action);

        POSTS_CACHE[content] = unescapeAmps(rawContent);
    }

    return POSTS_CACHE[content];
}

export function questionLinks($filter, $i18n, content) {
    if (!QUESTIONS_CACHE[content]) {
        const linky = $filter('linky');
        const attributeFn = url => {
            // http/https/ftp/sftp/mailto are supported by linky, we only allow https and mailto
            if (url.startsWith('https://') || url.startsWith('mailto:')) {
                return { class: 'link' };
            }
            return {
                href: '#',
                target: '',
                class: 'link disabled',
                title: i18nWithFallback(
                    $i18n,
                    'qna.tooltip.link_not_allowed',
                    'This link is not secure. Only https links are allowed.'
                )
            };
        };
        const rawContent = linky(content, '_blank', attributeFn);

        QUESTIONS_CACHE[content] = unescapeAmps(rawContent);
    }

    return QUESTIONS_CACHE[content];
}

const staticAssetSuffix = "";
export function appResource(resource) {
    const parts = resource.split('.');
    const ext = parts.pop();
    const baseName = parts.join('.');
    // we do not host these from the CDN in production as
    // Web/Service Workers have a strict content-origin policy [unconfirmed]
    return `/webapp/static/${"1.150.0"}/${baseName}${staticAssetSuffix}.${ext}`;
}

export function secureStripHTML($sce, html) {
    return stripHTML($sce.getTrustedHtml(html));
}

angular
    .module('maestro.filters', [])
    .filter('takeInitials', [ () => takeInitials ])
    .filter('assetUrl', assetUrlFilter)
    .filter('iconUrl', iconUrlFilter)
    .filter('time', [ () => time ])
    .filter('shortDate', [ () => shortDate ])
    .filter('shortTime', [ () => shortTime ])
    .filter('timeDisplay', [ () => timeDisplay ])
    .filter('humanizeDuration', [ () => humanizeDuration ])
    .filter('appResource', [ () => appResource ])
    .filter('stripHTML', [ '$sce', $sce => html => secureStripHTML($sce, html) ])
    .filter('feedLinky', [ () => feedLinky ])
    .filter('unescape', [ () => unescapeAmps ])
    .filter('unixFromNow', [ () => unixFromNow ])
    .filter('postLinks', [ '$filter', $filter => (content, links, action) => postLinks($filter, content, links, action) ])
    .filter('questionLinks', [ '$filter', '$i18n', ($filter, $i18n) => (content) => questionLinks($filter, $i18n, content) ]);
